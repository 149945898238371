import React from "react";
import ProductLayout from "../../../components/Layout/productLayout";
import ProductNavigation from "../../../components/Layout/productNavigation";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../../components/seo";

const TechSpecs = () => {
  const query = useStaticQuery(graphql`
  {
    prismicProducts(uid: { eq: "intercityaeolus" }) {
          data {
            seo_title
            seo_description
            product_name {
              text
            }
            battery_capacity
            battery_life
            battery_type
            battery_warranty
            charger
            charging_time
            controller_brand
            controller_warranty
            front_brakes
            front_tyre
            inclination
            kerb_weight
            motor
            motor_warranty
            power
            rear_tyre
            rear_brakes
            range__in_km_
            range
            product_warranty
            wheelbase
            vehicle_width
            vehicle_length
            vehicle_height
            tagline
            suspensions
            top_speed
          }
        }
      }
  `);


  return (
    <>
    <SEO 
    title = {query.prismicProducts.data.seo_title}
    desc = {query.prismicProducts.data.seo_description}/>
      <ProductLayout>
      <ProductNavigation
          productName="Intercity Aeolus"
          featuresLink="/products/intercity-aeolus/"
          buyLink="/products/intercity-aeolus/buy"
          techSpecsLink="/products/intercity-aeolus/specs"
          faqLink="/products/intercity-aeolus/faq"
        />
        <div className="my-5 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto grid grid-cols-2 mx-6">
          <div className="col-span-2 py-4">
            <h2 className="text-2xl md:text-3xl text-gray-700 font-semibold">
              Technical Specification
            </h2>
          </div>
          <div className="col-span-2 py-2">
            <table className="table-auto w-full divide-y divide-gray-200">
              <tr>
                <td className=" pt-6 pb-4 text-xl md:text-2xl font-semibold text-blue-500">
                  Performance
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Speed
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.top_speed}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Range
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.range}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Power
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.power}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Inclination
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.inclination}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Motor
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.motor}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Controller
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.controller_brand}
                </td>
              </tr>
              <tr>
                <td className=" pt-6 pb-4 text-xl md:text-2xl font-semibold text-blue-500">
                  Design
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Length
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.vehicle_length}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Width
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.vehicle_width}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Height
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.vehicle_height}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Wheelbase
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.wheelbase}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Front Tyre
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.front_tyre}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Rear Tyre
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.rear_tyre}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Front Brakes
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.front_brakes}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Rear Brakes
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.rear_brakes}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Suspensions
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.suspensions}
                </td>
              </tr>
              <tr>
                <td className=" pt-6 pb-4 text-xl md:text-2xl font-semibold text-blue-500">
                  Battery
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Type
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.battery_type}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Capacity
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.battery_capacity}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Range
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.range}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Charging Time
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.charging_time}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Charger
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.charger}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Battery Life
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.battery_life}
                </td>
              </tr>
              <tr>
                <td className=" pt-6 pb-4 text-xl md:text-2xl font-semibold text-blue-500">
                  Warranty
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Vehicle
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.product_warranty}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Battery
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.battery_warranty}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Motor
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.motor_warranty}
                </td>
              </tr>
              <tr>
                <td className="py-3 md:py-4 text-sm md:text-base font-medium text-gray-600">
                  Controller
                </td>
                <td className="py-3 md:py-4 text-sm md:text-base font-regular text-gray-500">
                  {query.prismicProducts.data.controller_warranty}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </ProductLayout>
    </>
  );
};

export default TechSpecs;
